import type { GetServerSidePropsContext, NextPage } from 'next';
import Image from 'next/image';

import { HomeApiService, WebAppApiService } from 'services';
import { IHomeModel, IWebAppModel } from 'models';

import { PagesType } from 'common/pages-type';
import Menu from '@/components/layout/menu';

import useMediaQuery from 'helpers/use-media-query';

import styles from './home.module.scss';
import { FunctionComponent } from 'react';
import ContentRenderer from '@/components/editorjs/editorjs-renderer';
import Head from 'next/head';

interface IHomePageProps {
  webApp: IWebAppModel;
  home: IHomeModel;
  previewMode: boolean;
}

const HeaderVideoYoutube: FunctionComponent<{
  url: string;
}> = ({ url }) => {
  return (
    <div className={styles['video']}>
      <iframe
        id="player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title="YouTube video player"
        width="100%"
        height="100%"
        src={url}></iframe>
      <div className={styles['mask-video']}></div>
    </div>
  );
};

const HeaderImage: FunctionComponent<{
  url: string;
}> = ({ url }) => {
  return (
    <div className={styles['image']}>
      <Image src={url} layout="fill" alt="header image" unoptimized priority />
    </div>
  );
};

const HomePage: NextPage<IHomePageProps> = ({ webApp, home, previewMode }) => {
  const getYoutubeUrl = () => {
    return `https://www.youtube.com/embed/${home.videoYoutubeId}?playlist=${home.videoYoutubeId}&loop=1&autoplay=1&showinfo=0&wmode=opaque&playsinline=1&autohide=1&enablejsapi=1&iv_load_policy=3&modestbranding=1&mute=1`;
  };

  const isBreakpoint = useMediaQuery(767);

  return (
    <div className={styles.container}>
      <Head>
        <title>Team Bertagne | Cuges-les-Pins</title>
        <meta
          name="description"
          content="Team Bertagne club de trail running à Cuges les pins dans les bouches du rhône"></meta>
      </Head>
      <main>
        {isBreakpoint ? (
          <HeaderImage url={home.image} />
        ) : (
          <HeaderVideoYoutube url={getYoutubeUrl()} />
        )}
        <div className={styles['content']}>
          <Image
            src={webApp.logoUrl}
            alt={home.title}
            width={150}
            height={150}
            unoptimized
          />
          <div className={styles['menu']}>
            <Menu
              previewMode={previewMode}
              origin={PagesType.Home}
              hideHome={true}
              forceInline={true}></Menu>
          </div>
          <div className={styles['bloc-texte']}>
            <h1 className={styles.title}>{home.title}</h1>
            <ContentRenderer content={home.description} />
          </div>
        </div>
      </main>
    </div>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  try {
    const webAppService = new WebAppApiService();
    const webapp = await webAppService.getWebApp(context);

    const homeService = new HomeApiService();
    const home = await homeService.getHome(context);

    return {
      props: {
        webApp: webapp,
        home: home,
        previewMode: context.preview ?? false,
      } as IHomePageProps,
    };
  } catch {
    return {
      props: {},
      redirect: {
        destination: '/signin',
        permanent: false,
      },
    };
  }
};

export default HomePage;
