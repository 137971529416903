import { ComponentType } from 'react';
import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
} from 'html-react-parser';
import dynamic from 'next/dynamic';

// Renderer spécifique pour un tableau
// table {
//   margin-left: auto;
//   margin-right: auto;
// }

const Output: ComponentType<{ renderers: any; data: string }> = dynamic(
  async () => (await import('editorjs-react-renderer')).default,
  {
    ssr: false,
  },
);

type CustomParagraphRendererProps = {
  data: any;
  classNames: any;
  config: any;
};

const CustomParagraphRenderer = ({
  data,
  classNames,
  config,
}: CustomParagraphRendererProps) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.name == 'a' && domNode.attribs.href) {
          return (
            <a href={domNode.attribs.href} target="blank">
              {domToReact(domNode.children, options)}
            </a>
          );
        }
      }
    },
  };

  let content = null;

  if (typeof data === 'string') {
    content = data;
  } else if (
    typeof data === 'object' &&
    data.text &&
    typeof data.text === 'string'
  ) {
    content = data.text;
  }

  let alignement = 'left';
  if (
    typeof data === 'object' &&
    data.alignment &&
    typeof data.alignment === 'string'
  ) {
    alignement = data.alignment;
  }

  return content ? (
    <p
      style={{ textAlign: alignement as CanvasTextAlign }}
      className={classNames}>
      {parse(content, options)}
    </p>
  ) : (
    ''
  );
};

const renderers = {
  paragraph: CustomParagraphRenderer,
};

type Props = {
  content: string;
};

export default function ContentRenderer({ content }: Props) {
  if (!content) return <></>;

  return <Output renderers={renderers} data={JSON.parse(content)} />;
}
